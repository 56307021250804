@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
.animate-slide-in-from-top {
    animation: slideInFromTop 1s ease-out forwards;
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.animate-slide-in-from-bottom {
    animation: slideInFromBottom 1s ease-out forwards;
}

.animate-slide-in-from-left {
    animation: slideInFromLeft 1s ease-out forwards;
}

.animate-slide-in-from-right {
    animation: slideInFromRight 1s ease-out forwards;
}

@keyframes typing {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.typing-effect {
    overflow: hidden;
    white-space: nowrap;
    animation: typing 2s steps(40, end);
}

.hidden-text {
    opacity: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeIn {
    animation: fadeIn 1s ease-in-out forwards;
}